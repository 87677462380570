import { useState } from 'react';
import { Tag, DuplicateIcon, CheckIcon } from '@viewthespace/components';
import { useTranslation } from 'react-i18next';
import { ContentBlockWrapper } from '..';

import { useMicrositeFormContext } from '../../utils';
import Host from './Host';

export default function Domain() {
  const { t } = useTranslation('admin');
  const { microsite } = useMicrositeFormContext();

  return (
    <ContentBlockWrapper
      contentBlockId="domain"
      wrapInDraggable={false}
      title={t('microsite.sections.domainInformation')}
      section="domain"
    >
      <Host />
      <NsRecords nsRecords={microsite.nsRecords} />
    </ContentBlockWrapper>
  );
}

const NsRecords = ({ nsRecords }) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const { t } = useTranslation('admin');

  return (
    <>
      <div className="flex justify-between">
        <span className="font-body-medium-emphasis text-black-100">
          {t('microsite.domainName.nameservers.title')}
        </span>
        {nsRecords?.length && (
          <button
            data-testid="copy-records-button"
            className="mt-[-2px]"
            type="button"
            onClick={async () => {
              setIsCopy(true);
              await navigator.clipboard.writeText(nsRecords.join('\n'));
              setTimeout(() => {
                setIsCopy(false);
              }, 3000);
            }}
          >
            {isCopy ? (
              <div className="flex gap-1">
                <span className="font-body-medium text-text-success">
                  {t('microsite.domainName.nameservers.copied')}
                </span>
                <CheckIcon color="text-success" className="mt-[1px]" />
              </div>
            ) : (
              <div className="flex">
                <DuplicateIcon className="mt-[1px]" />
              </div>
            )}
          </button>
        )}
      </div>
      {nsRecords?.length ? (
        <div className="flex flex-col mt-[-8px]">
          {nsRecords.map(nsRecord => {
            return (
              <span key={nsRecord} className="font-body-medium text-black-055">
                {nsRecord}
              </span>
            );
          })}
        </div>
      ) : (
        <div className="mt-[-8px]">
          <Tag text={t('microsite.domainName.pending')} color="attention" />
        </div>
      )}
    </>
  );
};
