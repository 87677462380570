import {
  TerraSelect,
  RadioGroup,
  FieldWrapper,
  TextInput,
  TextArea,
  Checkbox,
} from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { colord } from 'colord';
import { useCallback, useState } from 'react';
import { Dictionary, mapKeys, pickBy, uniq } from 'lodash';
import { Prompt } from 'react-router-dom';
import { AdminMicrosite, CloudinaryImageParams, GenericContentBlock } from '@root/types';
import FormSubmitter from '@components/shared/Admin/FormSubmitter';
import { ColorGroup } from '@components/shared/ColorPicker/ColorPickerColorGroup';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import ColorPicker from './ColorPicker';
import ImageUploaderWithCropper from './ImageUploaderWithCropper';
import { PreviewSection } from './EditMicrosite';
import TextColorSelector from './TextColorSelector';
import { Section, STATUSES, SECTIONS, MicrositeFormContextProvider } from './utils';
import Pages from './Pages';
import {
  ContentBlockWrapper,
  FooterContentBlock,
  HeaderContentBlock,
  AmenitiesContentBlock,
  AvailabilityContentBlock,
  CertificationsContentBlock,
  ContactContentBlock,
  GalleryContentBlock,
  NavContentBlock,
  SitewideContentBlock,
  BuildingInfoContentBlock,
  LocationContentBlock,
  ContentBlockProps,
  DomainContentBlock,
  MetadataContentBlock,
} from './ContentBlocks';

const dontCareProps: ContentBlockProps = {
  index: 1,
  page: 'home',
  id: '1',
  wrapInDraggable: false,
  anchorSlug: 'home',
};

export default function FormFields({
  microsite,
  sendPreviewTo,
  hasVideo,
  buildingId,
  refetch,
}: {
  microsite: AdminMicrosite;
  sendPreviewTo: (args: Partial<PreviewSection>) => void;
  hasVideo: boolean;
  buildingId: string;
  refetch: () => void;
}) {
  const { t } = useTranslation('admin');
  const [resetKey, setResetKey] = useState(0);
  const flags = useFlags();

  const { setFieldValue, values, setFieldTouched, dirty } =
    useFormikContext<Partial<AdminMicrosite>>();

  const changeFontColorWithColorChange =
    (fontAttribute: string) => (color: string, prevColor: string) => {
      const prevColorWithoutAlpha = prevColor.slice(0, 7);
      const colorWithoutAlpha = color.slice(0, 7);
      if (prevColorWithoutAlpha !== colorWithoutAlpha) {
        setFieldValue(fontAttribute, colord(colorWithoutAlpha).isDark() ? '#ffffff' : '#000000');
        setFieldTouched(fontAttribute, true);
      }
    };

  const [openSections, setOpenSections] = useState<Section[]>([]);
  const setOpen = (section: Section) => setOpenSections([...openSections, section]);
  const setClosed = (section: Section) => setOpenSections(openSections.filter(s => s !== section));
  const getIsOpen = (section: Section) => openSections.includes(section);

  const anySectionsOpened = openSections.length > 0;

  const colors = useCallback(() => {
    const colorFields = pickBy(
      {
        ...values,
        ...values.genericContentBlocks?.reduce(
          (acc, cur, i) => ({
            ...acc,
            ...mapKeys(cur, (_, key) => `${key}.${i}`),
          }),
          {},
        ),
      },
      (_, key) => key.toLowerCase().includes('color'),
    ) as Dictionary<string>;

    return uniq(Object.values(colorFields).map(c => c?.toLowerCase && c.toLowerCase().slice(0, 7)))
      .filter(c => c && c !== 'transpa') // filter out transparent
      .sort();
  }, [values])();

  const colorGroups: ColorGroup[] = [{ title: t('microsite.colorsInThisMicrosite'), colors }];

  return (
    <MicrositeFormContextProvider
      value={{
        getIsOpen,
        setOpen,
        setClosed,
        colorGroups,
        changeFontColorWithColorChange,
        microsite,
        sendPreviewTo,
        resetKey,
        hasVideo,
        buildingId,
        refetch,
      }}
    >
      <div className="flex flex-col gap-4 mb-4">
        <FieldWrapper
          name="previewUrl"
          labelText={t('microsite.previewUrl.title')}
          containerClass="!mt-1 !mb-0"
        >
          <a href={microsite.previewUrl} target="_blank" rel="noreferrer">
            {microsite.previewUrl}
          </a>
        </FieldWrapper>
        <TerraSelect
          labelText={t('microsite.status.title')}
          name="status"
          options={STATUSES.map(status => ({
            value: status,
            label: t(`microsite.status.${status}`),
          }))}
          isClearable={false}
          containerClass="!mb-0"
        />
        <Checkbox name="isDemo" labelClassName="!mb-0" className="!pt-[2px]">
          {t('microsite.isDemo.title')}
        </Checkbox>
        {microsite.liveUrl ? (
          <FieldWrapper name="previewUrl" labelText={t('microsite.liveUrl')} containerClass="!mb-0">
            <a href={microsite.liveUrl} target="_blank" rel="noreferrer">
              {microsite.liveUrl}
            </a>
          </FieldWrapper>
        ) : null}
        <div className="flex gap-2 flex-col">
          {flags['market.automated-microsite-domain-setup'] ? <DomainContentBlock /> : null}
          {flags['market-office.meta-tag-field'] ? <MetadataContentBlock /> : null}
        </div>
      </div>

      <div className="flex justify-between sticky top-0 bg-background-primary pb-2 z-[2]">
        <h2 className="font-subtitle">{t('microsite.stylingOptions')}</h2>
        <button
          className="text-cta-primary-default underline"
          type="button"
          onClick={() => {
            if (anySectionsOpened) {
              setOpenSections([]);
            } else {
              const genericContentBlockIDs =
                values.genericContentBlocks?.map(block => `generic-content-block-${block.id}`) ||
                [];
              setOpenSections([...genericContentBlockIDs, ...SECTIONS]);
            }
          }}
        >
          {t(anySectionsOpened ? 'microsite.collapseAll' : 'microsite.expandAll')}
        </button>
      </div>

      <div className="flex flex-col gap-2">
        <SitewideContentBlock />
        <NavContentBlock />
        <FooterContentBlock />

        {flags['market-office.flexible-layouts-on-microsite'] ? (
          <Pages />
        ) : (
          <>
            <HeaderContentBlock {...dontCareProps} />
            <BuildingInfoContentBlock {...dontCareProps} />

            {values.genericContentBlocks?.map((contentBlock, i) => {
              if (contentBlock.page !== 'home') return null;
              return (
                <GenericContentBlockFieldSection
                  contentBlock={contentBlock}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`homeContentBlock-${i}`}
                  index={i}
                  page="home"
                  sendPreviewTo={sendPreviewTo}
                  changeFontColorWithColorChange={changeFontColorWithColorChange}
                  section="homeContentBlock"
                  colorGroups={colorGroups}
                  resetKey={resetKey}
                />
              );
            })}

            <LocationContentBlock {...dontCareProps} />

            {values.genericContentBlocks!.map((contentBlock, i) => {
              if (contentBlock.page !== 'features') return null;
              return (
                <GenericContentBlockFieldSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={`featuresContentBlock-${i}`}
                  contentBlock={contentBlock}
                  index={i}
                  sendPreviewTo={sendPreviewTo}
                  changeFontColorWithColorChange={changeFontColorWithColorChange}
                  section="featuresContentBlock"
                  page="features"
                  colorGroups={colorGroups}
                  resetKey={resetKey}
                />
              );
            })}

            <AmenitiesContentBlock {...dontCareProps} />
            <CertificationsContentBlock {...dontCareProps} />
            <GalleryContentBlock {...dontCareProps} />
            <AvailabilityContentBlock {...dontCareProps} />

            {values.genericContentBlocks!.map((contentBlock, i) => {
              if (contentBlock.page !== 'contact') return null;
              return (
                <GenericContentBlockFieldSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={`contactContentBlock-${i}`}
                  contentBlock={contentBlock}
                  index={i}
                  sendPreviewTo={sendPreviewTo}
                  changeFontColorWithColorChange={changeFontColorWithColorChange}
                  section="contactContentBlock"
                  page="contact"
                  colorGroups={colorGroups}
                  resetKey={resetKey}
                />
              );
            })}

            <ContactContentBlock {...dontCareProps} />
          </>
        )}
      </div>
      <FormSubmitter showCancelButton includePlaceholder onCancel={() => setResetKey(k => k + 1)} />
      <Prompt when={dirty} message={t('microsite.leaveWithoutSavingConfirmation')} />
    </MicrositeFormContextProvider>
  );
}

const GenericContentBlockFieldSection = ({
  contentBlock,
  index,
  sendPreviewTo,
  page = 'home',
  changeFontColorWithColorChange,
  section,
  colorGroups,
  resetKey,
}: {
  contentBlock: GenericContentBlock;
  section: Section;
  page: String;
  index: number;
  sendPreviewTo: (any) => void;
  changeFontColorWithColorChange: (string) => (color: string, prevColor: string) => void;
  colorGroups: ColorGroup[];
  resetKey: number;
}) => {
  const { t } = useTranslation('admin');
  const { setFieldValue } = useFormikContext<Partial<AdminMicrosite>>();
  const getFieldName = (fieldName: string) => `genericContentBlocks[${index}].${fieldName}`;

  const flags = useFlags();
  const sectionTitlesAreRichText = flags['market.building-descriptions-support-rich-text'];

  return (
    <ContentBlockWrapper
      title={t(`microsite.sections.${section}`)}
      section={section}
      onClick={() => sendPreviewTo({ page, element: section })}
      wrapInDraggable={false}
      contentBlockId={contentBlock.id}
      {...dontCareProps}
    >
      <Checkbox
        name={getFieldName('visible')}
        data-testid="home-hero-image-style-selector"
        labelClassName="!mb-0"
        className="!pt-[2px]"
      >
        {t('microsite.genericContentBlock.visible.title')}
      </Checkbox>
      <RadioGroup
        name={getFieldName('style')}
        options={[
          { value: 'image_and_text', label: t('microsite.genericContentBlock.style.imageAndText') },
          { value: 'image', label: t('microsite.genericContentBlock.style.imageOnly') },
        ]}
        labelText={t('microsite.genericContentBlock.style.title')}
        containerClass="!mb-0"
        fast={!!flags['market-office.microsite-performance-optimizations']}
      />
      {contentBlock.style === 'image_and_text' && (
        <>
          <ColorPicker
            colorGroups={colorGroups}
            name={getFieldName('backgroundColor')}
            labelText={t('microsite.genericContentBlock.backgroundColor.title')}
            onChange={changeFontColorWithColorChange(getFieldName('textColor'))}
          />
          {flags['market-office.text-color-selector'] && (
            <TextColorSelector name={getFieldName('textColor')} />
          )}

          {sectionTitlesAreRichText ? (
            <RichTextEditorInput
              name={getFieldName('title')}
              labelText={t('microsite.genericContentBlock.sectionTitle.title')}
              hideLabel
              editorClassName="min-h-[100px] bg-white"
              excludeMenuItems={
                flags['market.microsite-font-sizing-on-editors']
                  ? ['bulletList']
                  : ['bulletList', 'textSize']
              }
              initialContent={contentBlock.title}
              defaultTextSize={
                flags['market.microsite-font-sizing-on-editors'] ? 'header32' : undefined
              }
              resetKey={resetKey}
              fast={!!flags['market-office.microsite-performance-optimizations']}
              shouldDebounce
            />
          ) : (
            <TextInput
              name={getFieldName('title')}
              labelText={t('microsite.genericContentBlock.sectionTitle.title')}
              containerClass="!mb-0"
            />
          )}

          {flags['market.building-descriptions-support-rich-text'] ? (
            <RichTextEditorInput
              name={getFieldName('description')}
              labelText={t('microsite.genericContentBlock.description.title')}
              hideLabel
              editorClassName="min-h-[100px] bg-white"
              initialContent={contentBlock.description}
              excludeMenuItems={
                flags['market.microsite-font-sizing-on-editors'] ? undefined : ['textSize']
              }
              defaultTextSize={
                flags['market.microsite-font-sizing-on-editors'] ? 'body18' : undefined
              }
              resetKey={resetKey}
              fast={!!flags['market-office.microsite-performance-optimizations']}
              shouldDebounce
            />
          ) : (
            <TextArea
              name={getFieldName('description')}
              labelText={t('microsite.genericContentBlock.description.title')}
              description={t('microsite.acceptsHtmlDescription')}
              descriptionClass="font-body-small text-black-055"
              containerClass="!mb-0"
              className="h-[350px]"
            />
          )}
        </>
      )}
      <ImageUploaderWithCropper
        img={contentBlock.image as CloudinaryImageParams | null}
        onChange={({ img, imageFit }) => {
          setFieldValue(getFieldName('image'), img);
          setFieldValue(getFieldName('imageFit'), imageFit);
        }}
        labelText={t('microsite.genericContentBlock.image.title')}
        name={getFieldName('image')}
        alt={t('microsite.genericContentBlock.image.title')}
        imagePreviewClassname={
          contentBlock.style === 'image_and_text' ? '!w-[150px] !h-[150px]' : '!w-[150px] !h-[68px]'
        }
        cropBoxDimentions={{
          width: 400,
          height: contentBlock.style === 'image_and_text' ? 400 : 180,
        }}
        initialImageFit={contentBlock.imageFit}
        imageFitFieldName={getFieldName('imageFit')}
      />
      {contentBlock.style === 'image_and_text' && (
        <RadioGroup
          options={[
            { value: 'left', label: t('microsite.genericContentBlock.imagePlacement.left') },
            { value: 'right', label: t('microsite.genericContentBlock.imagePlacement.right') },
          ]}
          name={getFieldName('imagePlacement')}
          labelText={t('microsite.genericContentBlock.imagePlacement.title')}
          containerClass="!mb-0"
          fast={!!flags['market-office.microsite-performance-optimizations']}
        />
      )}
      {flags['market-office.add-image-fit-to-content-blocks'] ? (
        <RadioGroup
          options={[
            {
              value: 'fit_to_space',
              label: t('microsite.genericContentBlock.imageFit.fit_to_space'),
            },
            { value: 'fill_space', label: t('microsite.genericContentBlock.imageFit.fill_space') },
          ]}
          name={getFieldName('imageFit')}
          labelText={t('microsite.genericContentBlock.imageFit.title')}
          containerClass="!mb-0"
          fast={!!flags['market-office.microsite-performance-optimizations']}
        />
      ) : null}
    </ContentBlockWrapper>
  );
};
