import { CloudinaryImageParams, AdminMicrosite } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RadioGroup } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ContentBlockWrapper } from '..';
import ImageUploader from '../../ImageUploader';
import FontPicker from '../../FontPicker';
import ColorPicker from '../../ColorPicker';
import { useMicrositeFormContext } from '../../utils';
import PageLayoutSelector from './PageLayoutSelector';

export default function Sidewide() {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { colorGroups, microsite, changeFontColorWithColorChange } = useMicrositeFormContext();

  return (
    <ContentBlockWrapper
      contentBlockId="sitewide"
      wrapInDraggable={false}
      title={t('microsite.sections.sitewide')}
      section="sitewide"
    >
      {microsite.flexibleLayoutsEnabled ? <PageLayoutSelector /> : null}
      <FontPicker />
      <ImageUploader
        img={values.favicon as CloudinaryImageParams | null}
        onChange={img => {
          setFieldValue('favicon', img);
          setFieldTouched('favicon', true);
        }}
        labelText={t('microsite.favicon.title')}
        name="favicon"
        alt={t('microsite.favicon.title')}
        imageClassName="!h-2 !w-2"
        transformations={{ crop: 'lpad', height: 48, width: 48 }}
        urlTransformations="c_lpad,h_48,w_48"
        helpText={t('microsite.favicon.helpText')}
      />
      {microsite.floatingBrochureButtonEnabled && microsite.hasBrochure ? (
        <ColorPicker
          colorGroups={colorGroups}
          name="brochureButtonColor"
          onChange={changeFontColorWithColorChange('brochureButtonTextColor')}
          labelText={t('microsite.brochureButtonColor')}
          data-testid="brochureButtonColorPicker"
        />
      ) : null}
      {flags['market-office.flexible-layouts-on-microsite'] ? (
        <>
          <RadioGroup
            name="sectionFraming"
            options={[
              { value: 'false', label: t('common:no') },
              { value: 'true', label: t('common:yes') },
            ]}
            optionsInRow
            labelText={t('microsite.sectionFraming')}
            containerClass="!mb-0"
            fast={!!flags['market-office.microsite-performance-optimizations']}
          />
          {values.sectionFraming === 'true' ? (
            <ColorPicker
              colorGroups={colorGroups}
              name="sectionFramingColor"
              labelText={t('microsite.sectionFramingColor')}
            />
          ) : null}
        </>
      ) : null}
    </ContentBlockWrapper>
  );
}
