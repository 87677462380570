import {
  RadioGroup,
  TextInput,
  TextArea,
  Checkbox as TruvaCheckbox,
  TerraSelect,
} from '@components/shared/forms';
import { Checkbox } from '@viewthespace/components';
import { AdminMicrosite, CloudinaryImageParams } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { ContentBlockProps, ContentBlockWrapper } from '..';
import ColorPicker from '../../ColorPicker';
import ImageUploaderWithCropper from '../../ImageUploaderWithCropper';
import TextColorSelector from '../../TextColorSelector';
import { isSinglePageLayout, useMicrositeFormContext } from '../../utils';
import ImageFitSelector from './ImageFitSelector';

export default function Generic({ id, page, index, anchorSlug }: ContentBlockProps) {
  const { t } = useTranslation('admin');
  const { setFieldValue, values, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();
  const { colorGroups, sendPreviewTo, changeFontColorWithColorChange, resetKey } =
    useMicrositeFormContext();

  const contentBlockIndex = values.genericContentBlocks!.findIndex(block => block.id === id);
  const contentBlock = values.genericContentBlocks!.find(block => block.id === id)!;

  const getFieldName = (fieldName: string) =>
    `genericContentBlocks[${contentBlockIndex}].${fieldName}`;

  const flags = useFlags();
  const sectionTitlesAreRichText = flags['market.building-descriptions-support-rich-text'];

  const getTextFromRichTextField = (fieldName: string) => {
    const richTextValue = contentBlock[fieldName];
    if (!richTextValue) return '';

    const parser = new DOMParser();
    return parser.parseFromString(richTextValue, 'text/html').body.innerText;
  };

  const getContentBlockName = () => {
    let name = '';
    if (contentBlock.style === 'image') {
      name = 'Image';
    } else {
      name = getTextFromRichTextField('title') || getTextFromRichTextField('description');

      if (!name && contentBlock.style === 'text_and_text') {
        name =
          getTextFromRichTextField('secondaryTitle') ||
          getTextFromRichTextField('secondaryDescription');
      }
    }

    return name || t('microsite.sections.untitledContentBlock');
  };

  const displayImageOptions =
    contentBlock.style === 'image_and_text' || contentBlock.style === 'image';

  const displayTextOptions =
    contentBlock.style === 'image_and_text' ||
    contentBlock.style === 'text_and_text' ||
    contentBlock.style === 'text';

  const displayBackgroundColorPicker =
    displayTextOptions || contentBlock.imageFit === 'fit_to_space';

  return (
    <ContentBlockWrapper
      index={index}
      dragAndDropEnabled
      contentBlockId={id}
      title={
        <span className="text-ellipsis overflow-hidden text-nowrap w-[calc(100%_-24px)]">
          <span>
            {flags['market-office.flexible-layouts-on-microsite']
              ? getContentBlockName()
              : t('microsite.sections.genericContentBlock', {
                  content: getContentBlockName(),
                })}
          </span>
        </span>
      }
      section={anchorSlug}
      onClick={() =>
        sendPreviewTo({
          page: isSinglePageLayout(values.layout!) ? 'home' : page,
          element: anchorSlug,
        })
      }
      isHidden={!contentBlock.visible}
    >
      {flags['market-office.hide-amenities-and-certifications'] ? (
        <Checkbox
          label={t('microsite.hideSection')}
          className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
          backgroundStyle="filled"
          isChecked={!contentBlock.visible}
          onChange={e => {
            setFieldValue(getFieldName('visible'), !e.value);
            setFieldTouched(getFieldName('visible'), true);
          }}
        />
      ) : (
        <TruvaCheckbox name={getFieldName('visible')} labelClassName="!mb-0" className="!pt-[2px]">
          {t('microsite.genericContentBlock.visible.title')}
        </TruvaCheckbox>
      )}
      {flags['market-office.additional-microsite-content-block-style-options'] ? (
        <TerraSelect
          labelText={t('microsite.genericContentBlock.style.title')}
          name={getFieldName('style')}
          value={contentBlock.style}
          options={[
            {
              label: t('microsite.genericContentBlock.style.imageAndText'),
              value: 'image_and_text',
            },
            { value: 'image', label: t('microsite.genericContentBlock.style.imageOnly') },
            { value: 'text', label: t('microsite.genericContentBlock.style.textOnly') },
            {
              label: t('microsite.genericContentBlock.style.textAndText'),
              value: 'text_and_text',
            },
          ]}
        />
      ) : (
        <RadioGroup
          name={getFieldName('style')}
          options={[
            {
              value: 'image_and_text',
              label: t('microsite.genericContentBlock.style.imageAndText'),
            },
            { value: 'image', label: t('microsite.genericContentBlock.style.imageOnly') },
          ]}
          labelText={t('microsite.genericContentBlock.style.title')}
          containerClass="!mb-0"
          fast={!!flags['market-office.microsite-performance-optimizations']}
        />
      )}
      {displayBackgroundColorPicker && (
        <ColorPicker
          colorGroups={colorGroups}
          name={getFieldName('backgroundColor')}
          labelText={t('microsite.genericContentBlock.backgroundColor.title')}
          onChange={changeFontColorWithColorChange(getFieldName('textColor'))}
        />
      )}
      {displayTextOptions && (
        <>
          {flags['market-office.text-color-selector'] && (
            <TextColorSelector name={getFieldName('textColor')} />
          )}

          {sectionTitlesAreRichText ? (
            <RichTextEditorInput
              name={getFieldName('title')}
              labelText={
                contentBlock.style === 'text_and_text'
                  ? t('microsite.genericContentBlock.leftSectionTitle.title')
                  : t('microsite.genericContentBlock.sectionTitle.title')
              }
              hideLabel
              editorClassName="min-h-[100px] bg-white"
              excludeMenuItems={
                flags['market.microsite-font-sizing-on-editors']
                  ? ['bulletList']
                  : ['bulletList', 'textSize']
              }
              initialContent={contentBlock.title}
              defaultTextSize={
                flags['market.microsite-font-sizing-on-editors'] ? 'header32' : undefined
              }
              resetKey={resetKey}
              fast={!!flags['market-office.microsite-performance-optimizations']}
              shouldDebounce
            />
          ) : (
            <TextInput
              name={getFieldName('title')}
              labelText={t('microsite.genericContentBlock.sectionTitle.title')}
              containerClass="!mb-0"
            />
          )}

          {flags['market.building-descriptions-support-rich-text'] ? (
            <RichTextEditorInput
              name={getFieldName('description')}
              labelText={
                contentBlock.style === 'text_and_text'
                  ? t('microsite.genericContentBlock.leftDescription.title')
                  : t('microsite.genericContentBlock.description.title')
              }
              hideLabel
              editorClassName="min-h-[100px] bg-white"
              initialContent={contentBlock.description}
              excludeMenuItems={
                flags['market.microsite-font-sizing-on-editors'] ? undefined : ['textSize']
              }
              defaultTextSize={
                flags['market.microsite-font-sizing-on-editors'] ? 'body18' : undefined
              }
              resetKey={resetKey}
              fast={!!flags['market-office.microsite-performance-optimizations']}
              shouldDebounce
            />
          ) : (
            <TextArea
              name={getFieldName('description')}
              labelText={t('microsite.genericContentBlock.description.title')}
              description={t('microsite.acceptsHtmlDescription')}
              descriptionClass="font-body-small text-black-055"
              containerClass="!mb-0"
              className="h-[350px]"
            />
          )}

          {contentBlock.style === 'text_and_text' && (
            <>
              <RichTextEditorInput
                name={getFieldName('secondaryTitle')}
                labelText={t('microsite.genericContentBlock.rightSectionTitle.title')}
                hideLabel
                editorClassName="min-h-[100px] bg-white"
                excludeMenuItems={
                  flags['market.microsite-font-sizing-on-editors']
                    ? ['bulletList']
                    : ['bulletList', 'textSize']
                }
                initialContent={contentBlock.secondaryTitle}
                defaultTextSize={
                  flags['market.microsite-font-sizing-on-editors'] ? 'header32' : undefined
                }
                resetKey={resetKey}
                fast={!!flags['market-office.microsite-performance-optimizations']}
                shouldDebounce
              />
              <RichTextEditorInput
                name={getFieldName('secondaryDescription')}
                labelText={t('microsite.genericContentBlock.rightDescription.title')}
                hideLabel
                editorClassName="min-h-[100px] bg-white"
                initialContent={contentBlock.secondaryDescription}
                excludeMenuItems={
                  flags['market.microsite-font-sizing-on-editors'] ? undefined : ['textSize']
                }
                defaultTextSize={
                  flags['market.microsite-font-sizing-on-editors'] ? 'body18' : undefined
                }
                resetKey={resetKey}
                fast={!!flags['market-office.microsite-performance-optimizations']}
                shouldDebounce
              />
            </>
          )}
        </>
      )}

      {displayImageOptions && (
        <ImageUploaderWithCropper
          img={contentBlock.image as CloudinaryImageParams | null}
          onChange={({ img, imageFit }) => {
            setFieldValue(getFieldName('image'), img);
            setFieldValue(getFieldName('imageFit'), imageFit);
          }}
          labelText={t('microsite.genericContentBlock.image.title')}
          name={getFieldName('image')}
          alt={t('microsite.genericContentBlock.image.title')}
          imagePreviewClassname={
            contentBlock.style === 'image_and_text'
              ? '!w-[150px] !h-[150px]'
              : '!w-[150px] !h-[68px]'
          }
          cropBoxDimentions={{
            width: 400,
            height: contentBlock.style === 'image_and_text' ? 400 : 180,
          }}
          initialImageFit={contentBlock.imageFit}
          imageFitFieldName={getFieldName('imageFit')}
        />
      )}
      {displayImageOptions && flags['market-office.add-image-fit-to-content-blocks'] ? (
        <ImageFitSelector
          fieldName={getFieldName('imageFit')}
          selectedImageFit={contentBlock.imageFit}
          onChange={fitOption => {
            // We want to reset any cropping or positioning on an image when changing the fit
            const contentBlockImage = {
              ...contentBlock.image,
              transformations: null,
              cropperCanvasData: null,
            };
            setFieldValue(getFieldName('image'), contentBlockImage);
            setFieldValue(getFieldName('imageFit'), fitOption);
            setFieldTouched(getFieldName('imageFit'), true);
          }}
        />
      ) : null}
      {contentBlock.style === 'image_and_text' ? (
        <RadioGroup
          options={[
            { value: 'left', label: t('microsite.genericContentBlock.imagePlacement.left') },
            {
              value: 'right',
              label: t('microsite.genericContentBlock.imagePlacement.right'),
            },
          ]}
          name={getFieldName('imagePlacement')}
          labelText={t('microsite.genericContentBlock.imagePlacement.title')}
          containerClass="!mb-0"
          fast={!!flags['market-office.microsite-performance-optimizations']}
        />
      ) : null}
    </ContentBlockWrapper>
  );
}
