import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import { notification } from 'antd';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { AdminMicrosite } from '@root/types';
import { update } from '@shared/typedApi';
import routes from '@root/routes';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Notification } from '@components/shared';
import { TextInput, Button, Dialog, InlineNotice, Tooltip } from '@viewthespace/components';
import { RawLabel as Label } from '@components/shared/forms';
import { sanitizeDomain, useMicrositeFormContext } from '../../utils';

const Host = () => {
  const { t } = useTranslation('admin');
  const { microsite, buildingId, refetch } = useMicrositeFormContext();
  const [isDomainConfirmationDialogOpen, setIsDomainConfirmationDialogOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const formWrapperRef = useRef(null);

  const hostValidationSchema = toFormikValidationSchema(
    z.object({
      host: z
        .string({
          required_error: t('microsite.domainName.validation.required'),
        })
        .regex(/\.[a-z]{2,}$/i, t('microsite.domainName.validation.missingExtension'))
        .regex(/^.+\./, t('microsite.domainName.validation.missingDomain')),
    }),
  );

  const formik = useFormik({
    initialValues: { host: microsite.host || '' },
    onSubmit: values => {
      mutation.mutate(values);
    },
    validationSchema: hostValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const mutation = useMutation(
    (form: Pick<AdminMicrosite, 'host'>) => {
      const { host } = form;
      return update(routes.api.admin.buildingMicrosite(buildingId), { host });
    },
    {
      onSuccess: () => {
        setIsDomainConfirmationDialogOpen(false);
        Notification.success({
          title: t('microsite.domainName.dialog.successMessage'),
          placement: 'topRight',
        });
        refetch();
      },
      onError: (error: Error) => {
        handleServerError(error);
      },
    },
  );

  const handleServerError = (error: Error) => {
    const parsedError = JSON.parse(error.message);
    const { field, message } = parsedError.errors[0];

    const errorTextToDisplay =
      field === 'host' && message === 'has already been taken'
        ? t('microsite.domainName.validation.domainTaken', { name: formik.values.host })
        : t('notification.generalError');

    Notification.error({
      title: t('common:somethingWentWrong'),
      text: errorTextToDisplay,
      placement: 'topRight',
      key: 'host-error',
    });
  };

  useEffect(() => {
    if (formWrapperRef.current) {
      setIsMounted(true);
    }
  }, [formWrapperRef]);

  const handleCloseDialog = () => {
    setIsDomainConfirmationDialogOpen(false);
  };

  const handleClickSetup = () => {
    notification.close('host-error');
    formik.setFieldValue('host', sanitizeDomain(formik.values.host));
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        setIsDomainConfirmationDialogOpen(true);
      }
    });
  };

  return (
    <>
      <div ref={formWrapperRef} className="flex flex-row-reverse gap-2 items-end">
        {microsite.host ? null : (
          <Button
            text={t('microsite.domainName.setUp')}
            ariaLabel={t('microsite.domainName.setUp') || 'Set up'}
            variant="primary"
            shape="pill"
            onClick={() => handleClickSetup()}
            className={cn('h-fit', formik.errors.host && 'mb-[22px]')}
          />
        )}
        {isMounted &&
          createPortal(
            <form className="w-full">
              <Label name="host" className="!font-bold !text-black-100">
                {t('microsite.domainName.title')}
              </Label>
              {microsite.host ? (
                <Tooltip
                  placement="top"
                  content={t('microsite.domainName.tooltip')}
                  trigger={
                    <TextInput
                      label="Domain name"
                      hideLabel
                      className="w-full !mt-0.5"
                      name="host"
                      value={microsite.host}
                      isReadonly
                    />
                  }
                />
              ) : (
                <TextInput
                  label="Domain name"
                  hideLabel
                  placeholder={t('microsite.domainName.placeholder')}
                  backgroundStyle="filled"
                  className="w-full !mt-0.5"
                  name="host"
                  onChange={e => {
                    formik.setFieldValue('host', e.value);
                  }}
                  value={formik.values.host}
                  errorText={formik.errors.host}
                />
              )}
            </form>,
            formWrapperRef.current!,
          )}
      </div>
      {isDomainConfirmationDialogOpen && (
        <Dialog
          align="default"
          closeOnClickAway={false}
          header={t('microsite.domainName.dialog.header')}
          isOpen={isDomainConfirmationDialogOpen}
          onClose={handleCloseDialog}
          width="small"
          primaryButton={{
            text: t('common:submit'),
            onClick: formik.handleSubmit,
          }}
          secondaryButton={{
            text: t('common:cancel'),
            onClick: handleCloseDialog,
          }}
        >
          <div className="p-2 w-[480px]">
            <div className="font-body-medium">{t('microsite.domainName.dialog.domainName')}</div>
            <div className="font-body-medium-emphasis">{formik?.values?.host}</div>
            <InlineNotice
              className="mt-2"
              content={
                <span className="font-body-medium-emphasis">
                  {t('microsite.domainName.dialog.attentionMessage')}
                </span>
              }
              variant="attention"
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Host;
